.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0px 3px 15px 6px rgba(148, 148, 148, 0.1) !important;
  .content {
    width: 100%;
    .registerForm {
      text-align: left;
      .typography {
        margin-bottom: 20px;
        font-size: 36px;
      }
      .inputs {
        width: 300px;
        margin: 5px 0;
        background-color: #fff !important;
      }
    }
  }
  .buttonContainer {
    padding: 0;
    .button {
      flex: 2;
      margin-top: 20px;
      padding: 14px;
      color: #fff;
    }
  }
}
@media screen and (max-width: 520px) {
  .card {
    .content {
      .registerForm {
        .inputs {
          width: 250px;
          margin: 20px 0;
          height: 40px;
        }
      }
    }
    .button {
      width: 100px;
      margin-top: 20px;
      height: 50px;
    }
  }
}
