@import "../../assets/variables.scss";

.userForm {
  .header {
    .title {
      font-size: 24px;
      font-weight: 500;
      margin: 0;
    }
    .subtitle {
      margin: 0;
      margin-bottom: 20px;
      color: $textSecondary;
    }
  }
  padding: 28px;
  .button {
    margin-top: 5px;
  }
}
