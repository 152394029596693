@import "./assets/variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
}

.o-page {
  display: flex;
  height: 100%;
}

.o-card {
  background-color: white;
  border-radius: 9px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(127, 130, 146, 0.07);
  -moz-box-shadow: 0px 3px 6px 0px rgba(127, 130, 146, 0.07);
  box-shadow: 0px 3px 6px 0px rgba(127, 130, 146, 0.07);
}

.tooltip-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.tooltip-label {
  color: #b0b7c6;
  font-size: 16px;
}
.tooltip-value {
  color: $primary;
  font-size: 14px;
}

.tooltip-area-value {
  color: #b0b7c6;
  font-size: 14px;
}

.logo {
  height: 40vmin;
  pointer-events: none;
}

.link {
  color: $link;
  text-decoration: none;
}

//scrollbar non-standard hack
*::-webkit-scrollbar-corner {
  background-color: transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

// browser scrollbar coloring, no need to put to variables yet
// we copied from google search result
// scss-lint:disable ColorVariable PseudoElement
*::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.5);
}

*::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar-corner:vertical {
  background-color: transparent;
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 140px !important;
  }
}

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #f6f7fa;
  border-radius: 4px !important;
}

.MuiFilledInput-root.Mui-focused {
  background-color: #f6f7fa !important;
}

.MuiFilledInput-root:hover {
  background-color: #f6f7fa !important;
}

.MuiFilledInput-underline:before {
  border-bottom: 0 !important;
}
.MuiFilledInput-underline:after {
  border-bottom: 0 !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #7f8292 !important;
}
.MuiInputLabel-filled {
  color: #7f8292 !important;
}
.MuiFormLabel-root.Mui-error {
  color: #f44336 !important;
}

.MuiSelect-select:focus {
  background-color: #F6F7FA !important;
}

.fc .fc-button-group > .fc-button {
  background-color: #ffffff;
  color: #a2b0c9;
  border: 0;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: $primary !important;
  background-color: rgb(246, 249, 255) !important;
  border-color: rgb(246, 249, 255) !important;
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none !important;
}

.fc .fc-button-primary:hover {
  color: $primary !important;
  background-color: rgb(246, 249, 255) !important;
  border-color: rgb(246, 249, 255) !important;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc .fc-toolbar-title {
  font-size: 1.75em;
  margin: 0;
  color: #a2b0c9 !important;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  background-color: #ffffff;
  color: #a2b0c9 !important;
  border: 0 !important;
}

.fc .fc-button-primary:disabled {
  color: $primary !important;
  background-color: rgb(246, 249, 255) !important;
  border-color: rgb(246, 249, 255) !important;
}

hr {
  border-top: $textSecondary;
}
