.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-height: 100vh;
  margin-top: 20rem;
}
.apps {
  display: flex;
  flex-direction: row;
}
.title {
  font-size: 32px;
  padding: 0 2em;
  color: #76b2f7;
}
.img {
  width: 190px;
  margin: 30px;
}

@media screen and (max-width: 520px) {
  .apps {
    display: flex;
    flex-direction: column;
  }
  .container {
    justify-content: center;
    margin-top: -3rem;
  }
  .title {
    padding: 0 2em;
  }
}
