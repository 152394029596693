@import "../../../assets/variables.scss";
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.card {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 15px 6px rgba(148, 148, 148, 0.1) !important;
  .content {
    width: 100%;
    .inputs {
      width: 380px;
      margin: 5px 0;
    }
  }
}
@media screen and (max-width: 520px) {
  .card {
    .content {
      .registerForm {
        .inputs {
          width: 250px;
          margin: 20px 0;
          height: 40px;
        }
      }
    }
    .button {
      width: 100px;
      margin-top: 20px;
      height: 50px;
    }
  }
}
