.modal {
  padding: 10px;
  width: 50vh;
  .header {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    .title{
      display: flex;
      flex-direction: row;
      align-items: center;
      h2 {
      margin-block-start:0;
      margin-block-end: 0;
      }
    }
  }
  .close {
    display: flex;
    justify-content: flex-end;
  }
  .closeIcon:hover {
    cursor: pointer;
  }
  .modalText {
    text-align: left;
    align-items: left;
    justify-content: left;
    .text {
      margin: 20px 0;
    }
    .action {
      display: flex;
    }
  }
}

@media screen and (max-width: 520px) {
  .modal {
    padding: 10px;
    width: 40vh;
  }
}
