.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.card {
  padding: 20px;
}
.icon {
  text-align: center;
}
