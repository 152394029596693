@import "../../../assets/breakpoints.scss";
@import "../../../assets/variables.scss";

.login {
  display: flex;
  flex-direction: row;
  .loginBackground {
    .logo {
      padding: 40px;
    }
    width: 60%;
    background: url("../../../assets/login-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }
  .loginFormContainer {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .link {
      padding-top: 6px;
      padding-bottom: 6px;
      a {
        color: #69AAFF;
        text-decoration: none;
      }
    }
    .privacy {
      color: $textSecondary;
    }
    .inputs {
      width: 360px;
      margin: 5px 0;
    }
  }
}

@media screen and (max-width: $breakpoint-large) {
  .login {
    .loginBackground {
      width: 50%;
    }
    .loginFormContainer {
      width: 50%;
    }
  }
}

@media screen and (max-width: $breakpoint-medium) {
  .login {
    .loginBackground {
      display: none;
    }
    .loginFormContainer {
      width: 100%;
      height: 100vh;
    }
  }
}

@media screen and (max-width: 520px) {
  .login {
    .loginBackground {
      display: none;
    }
    .loginFormContainer {
      width: 100%;
      height: 100vh;
    }
  }
}
